import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["time"]


	connect() {
		if( this.hasTimeTarget) {
			const started = new Date(this.timeTarget.getAttribute("data-start-time")).getTime()
			this.stopwatch(this.timeTarget, started)
		}
	}

	stopwatch(el, started) {
		setInterval(function() {
			let now = new Date().getTime()
			let passed = now - started
		  let hours = Math.floor((passed % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})
	  	let minutes = Math.floor((passed % (1000 * 60 * 60)) / (1000 * 60)).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})
	  	let seconds = Math.floor((passed % (1000 * 60)) / 1000).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})
			el.innerHTML = `${hours}:${minutes}:${seconds}`
		}, 1000)
	}
}