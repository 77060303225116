import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["schedule_button", "date_input"] 
  static values = {dateInput: String}

  initialize() {
    console.log(this.schedule_buttonTargets)
    console.log(this.date_inputTargets)
  }

  show_date_input(event) {
    const dateInputID = event.target.getAttribute("data-date-input")
    const input = this.date_inputTargets.find(t => t.id === dateInputID)
    event.target.classList.add("hidden")
    input.classList.remove("hidden")
  }

  update_project_date(event) {
    let data = new FormData()

    const field = event.target.getAttribute("data-field")
    if (field === "stage_one_at") {
      data.append("project[stage_one_at]", event.target.value)
    } else {
      data.append("project[stage_two_at]", event.target.value)
    }

    Rails.ajax({
      type: "PATCH",
      url: this.url(event),
      dataType: "json",
      data: data,
      success: function (res) { console.log("Saved"); },
      error: function (err) { console.log(err); }
    })
  }

  url(event) {
    return event.target.dataset.url
  }

  remove_project_date(event) {
    let data = new FormData()
  
    const field = event.target.getAttribute("data-field")
    if (field === "stage_one_at") {
      data.append("project[stage_one_at]", "")
    } else {
      data.append("project[stage_two_at]", "")
    }
    Rails.ajax({
      type: "PATCH",
      url: this.url(event),
      dataType: "json",
      data: data,
      success: function (res) { console.log(res) },
      error: function (err) { console.log(err); }
    })

  
  }

}