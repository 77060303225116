import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["input_field"]

  update_completed(event) {
    let data = new FormData;
    let checboxId = event.target.id;
    let url = document.getElementById(event.target.id + "_form").action
    data.append("task[completed]", event.target.checked == true ? 1 : 0)
    Rails.ajax({
      type: "PATCH",
      url: url,
      dataType: "json",
      data: data,
      success: function(res) {
        // am modifying this solely for testing purposes
        document.getElementById(checboxId).value = 0;
      },
      error: ""
    })
  }

}