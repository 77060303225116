import { Controller } from "stimulus"       

export default class extends Controller {   
  static targets = [ "toggleable" ]         

  initialize() {
    console.log("Hello from stimulus");
  }

  toggle() {
    this.toggleableTargets.forEach( el => el.classList.toggle('open'));
  }        
}
